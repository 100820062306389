
import { computed, defineComponent, reactive } from 'vue';
import ServicoSistema from '@/servicos/MeuSistema/ServicoSistema';

export default defineComponent({
  name: 'CenarioFiscalDefinicaoIcms',
  props: {
    informacaoComplementar: {
      type: String,
      required: true,
    },
  },
  emits: ['update:informacaoComplementar'],
  setup(props, { emit }) {
    const servicoSistema = new ServicoSistema();
    const state = reactive({});

    const computedInformacaoComplementar = computed({
      get: () => props.informacaoComplementar,
      set: (informacaoComplementar: string) => {
        emit('update:informacaoComplementar', informacaoComplementar);
      },
    });

    function adicionarVariavel(texto: string) {
      computedInformacaoComplementar.value = servicoSistema.montaTextoComVariavelConteudo(computedInformacaoComplementar.value, texto);
    }

    return {
      props,
      state,
      computedInformacaoComplementar,
      adicionarVariavel,
    };
  },
});
