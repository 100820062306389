import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "ant-row" }
const _hoisted_2 = { class: "ant-col ant-col-xs-24 ant-col-xl-24" }
const _hoisted_3 = {
  key: 0,
  class: "acoes-item"
}
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { key: 2 }
const _hoisted_8 = { key: 3 }
const _hoisted_9 = { class: "ant-row" }
const _hoisted_10 = { class: "ant-col ant-col-xs-24 ant-col-xl-24" }
const _hoisted_11 = { class: "ant-row" }
const _hoisted_12 = { class: "ant-col ant-col-xs-24 ant-col-xl-24" }
const _hoisted_13 = {
  key: 0,
  class: "acoes-item"
}
const _hoisted_14 = ["onClick"]
const _hoisted_15 = ["onClick"]
const _hoisted_16 = { key: 1 }
const _hoisted_17 = { key: 2 }
const _hoisted_18 = { key: 3 }
const _hoisted_19 = { class: "ant-row" }
const _hoisted_20 = { class: "ant-col ant-col-xs-24 ant-col-xl-24" }
const _hoisted_21 = { class: "ant-row" }
const _hoisted_22 = { class: "ant-col ant-col-xs-24 ant-col-xl-24" }
const _hoisted_23 = {
  key: 0,
  class: "acoes-item"
}
const _hoisted_24 = ["onClick"]
const _hoisted_25 = ["onClick"]
const _hoisted_26 = { key: 1 }
const _hoisted_27 = { key: 2 }
const _hoisted_28 = { key: 3 }
const _hoisted_29 = { class: "ant-row" }
const _hoisted_30 = { class: "ant-col ant-col-xs-24 ant-col-xl-24" }
const _hoisted_31 = { class: "ant-row" }
const _hoisted_32 = { class: "ant-col ant-col-xs-24 ant-col-xl-24" }
const _hoisted_33 = {
  key: 0,
  class: "acoes-item"
}
const _hoisted_34 = ["onClick"]
const _hoisted_35 = ["onClick"]
const _hoisted_36 = { key: 1 }
const _hoisted_37 = { key: 2 }
const _hoisted_38 = { key: 3 }
const _hoisted_39 = { class: "ant-row" }
const _hoisted_40 = { class: "ant-col ant-col-xs-24 ant-col-xl-24" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_icone = _resolveComponent("icone")!
  const _component_mensagem_sem_dados = _resolveComponent("mensagem-sem-dados")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_card = _resolveComponent("card")!
  const _component_cenario_fiscal_definicao_icms_modal = _resolveComponent("cenario-fiscal-definicao-icms-modal")!
  const _component_cenario_fiscal_definicao_ipi_modal = _resolveComponent("cenario-fiscal-definicao-ipi-modal")!
  const _component_cenario_fiscal_definicao_pis_modal = _resolveComponent("cenario-fiscal-definicao-pis-modal")!
  const _component_cenario_fiscal_definicao_cofins_modal = _resolveComponent("cenario-fiscal-definicao-cofins-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_card, { titulo: "ICMS" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_a_form_item, {
              label: "Busca Rápida",
              class: "ss-margin-campos"
            }, {
              default: _withCtx(() => [
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  class: "ant-input",
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.buscaRapidaIcms) = $event)),
                  onKeyup: _cache[1] || (_cache[1] = ($event: any) => (_ctx.filtrarExcecoes(_ctx.ETipoImposto.Icms)))
                }, null, 544), [
                  [_vModelText, _ctx.state.buscaRapidaIcms]
                ])
              ]),
              _: 1
            })
          ])
        ]),
        _createVNode(_component_a_table, {
          columns: _ctx.state.colunasIcms.filter(item => item.visible),
          "data-source": _ctx.computedExcecoesIcms.filter(item => item.visivel),
          rowKey: "codigo",
          pagination: false,
          loading: _ctx.telaBase.carregando,
          scroll: { y: _ctx.defineAlturaScroll(38) },
          size: "small"
        }, {
          bodyCell: _withCtx(({ column, record, text }) => [
            (column.key === 'Acoes')
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createElementVNode("a", {
                    class: "visualizar",
                    onClick: ($event: any) => (_ctx.editarDefinicao(record))
                  }, [
                    _createVNode(_component_icone, { nome: "editar" })
                  ], 8, _hoisted_4),
                  _createElementVNode("a", {
                    onClick: ($event: any) => (_ctx.excluirDefinicao(record)),
                    class: "excluir"
                  }, [
                    _createVNode(_component_icone, { nome: "excluir" })
                  ], 8, _hoisted_5)
                ]))
              : (column.key === 'CodigoExcecao')
                ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(record.tipoItem === 1 ? record.codigoInternoProduto : record.ncm), 1))
                : (column.key === 'DescricaoExcecao')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(record.tipoItem === 1 ? record.descricaoProduto : record.descricaoNcm), 1))
                  : (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(text), 1))
          ]),
          emptyText: _withCtx(() => [
            _createVNode(_component_mensagem_sem_dados, {
              resultadoPesquisa: !_ctx.telaBase.carregando,
              mensagem: _ctx.telaBase.carregando ? 'Buscando informações, por favor aguarde...' : 'Nenhuma exceção definida'
            }, null, 8, ["resultadoPesquisa", "mensagem"])
          ]),
          _: 1
        }, 8, ["columns", "data-source", "loading", "scroll"]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("button", {
              class: "ant-btn ant-btn-secondary",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.incluirDefinicao(_ctx.ETipoImposto.Icms))),
              style: {"margin-top":"5px"}
            }, "Adicionar")
          ])
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_card, { titulo: "IPI" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _createVNode(_component_a_form_item, {
              label: "Busca Rápida",
              class: "ss-margin-campos"
            }, {
              default: _withCtx(() => [
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  class: "ant-input",
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.state.buscaRapidaIpi) = $event)),
                  onKeyup: _cache[4] || (_cache[4] = ($event: any) => (_ctx.filtrarExcecoes(_ctx.ETipoImposto.Ipi)))
                }, null, 544), [
                  [_vModelText, _ctx.state.buscaRapidaIpi]
                ])
              ]),
              _: 1
            })
          ])
        ]),
        _createVNode(_component_a_table, {
          columns: _ctx.state.colunasIpi.filter(item => item.visible),
          "data-source": _ctx.computedExcecoesIpi.filter(item => item.visivel),
          rowKey: "codigo",
          pagination: false,
          loading: _ctx.telaBase.carregando,
          scroll: { y: _ctx.defineAlturaScroll(38) },
          size: "small"
        }, {
          bodyCell: _withCtx(({ column, record, text }) => [
            (column.key === 'Acoes')
              ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                  _createElementVNode("a", {
                    class: "visualizar",
                    onClick: ($event: any) => (_ctx.editarDefinicao(record))
                  }, [
                    _createVNode(_component_icone, { nome: "editar" })
                  ], 8, _hoisted_14),
                  _createElementVNode("a", {
                    onClick: ($event: any) => (_ctx.excluirDefinicao(record)),
                    class: "excluir"
                  }, [
                    _createVNode(_component_icone, { nome: "excluir" })
                  ], 8, _hoisted_15)
                ]))
              : (column.key === 'CodigoExcecao')
                ? (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString(record.tipoItem === 1 ? record.codigoInternoProduto : record.ncm), 1))
                : (column.key === 'DescricaoExcecao')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString(record.tipoItem === 1 ? record.descricaoProduto : record.descricaoNcm), 1))
                  : (_openBlock(), _createElementBlock("span", _hoisted_18, _toDisplayString(text), 1))
          ]),
          emptyText: _withCtx(() => [
            _createVNode(_component_mensagem_sem_dados, {
              resultadoPesquisa: !_ctx.telaBase.carregando,
              mensagem: _ctx.telaBase.carregando ? 'Buscando informações, por favor aguarde...' : 'Nenhuma exceção definida'
            }, null, 8, ["resultadoPesquisa", "mensagem"])
          ]),
          _: 1
        }, 8, ["columns", "data-source", "loading", "scroll"]),
        _createElementVNode("div", _hoisted_19, [
          _createElementVNode("div", _hoisted_20, [
            _createElementVNode("button", {
              class: "ant-btn ant-btn-secondary",
              onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.incluirDefinicao(_ctx.ETipoImposto.Ipi))),
              style: {"margin-top":"5px"}
            }, "Adicionar")
          ])
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_card, { titulo: "PIS" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_21, [
          _createElementVNode("div", _hoisted_22, [
            _createVNode(_component_a_form_item, {
              label: "Busca Rápida",
              class: "ss-margin-campos"
            }, {
              default: _withCtx(() => [
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  class: "ant-input",
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.state.buscaRapidaPis) = $event)),
                  onKeyup: _cache[7] || (_cache[7] = ($event: any) => (_ctx.filtrarExcecoes(_ctx.ETipoImposto.Pis)))
                }, null, 544), [
                  [_vModelText, _ctx.state.buscaRapidaPis]
                ])
              ]),
              _: 1
            })
          ])
        ]),
        _createVNode(_component_a_table, {
          columns: _ctx.state.colunasPis.filter(item => item.visible),
          "data-source": _ctx.computedExcecoesPis.filter(item => item.visivel),
          rowKey: "codigo",
          pagination: false,
          loading: _ctx.telaBase.carregando,
          scroll: { y: _ctx.defineAlturaScroll(38) },
          size: "small"
        }, {
          bodyCell: _withCtx(({ column, record, text }) => [
            (column.key === 'Acoes')
              ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                  _createElementVNode("a", {
                    class: "visualizar",
                    onClick: ($event: any) => (_ctx.editarDefinicao(record))
                  }, [
                    _createVNode(_component_icone, { nome: "editar" })
                  ], 8, _hoisted_24),
                  _createElementVNode("a", {
                    onClick: ($event: any) => (_ctx.excluirDefinicao(record)),
                    class: "excluir"
                  }, [
                    _createVNode(_component_icone, { nome: "excluir" })
                  ], 8, _hoisted_25)
                ]))
              : (column.key === 'CodigoExcecao')
                ? (_openBlock(), _createElementBlock("span", _hoisted_26, _toDisplayString(record.tipoItem === 1 ? record.codigoInternoProduto : record.ncm), 1))
                : (column.key === 'DescricaoExcecao')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_27, _toDisplayString(record.tipoItem === 1 ? record.descricaoProduto : record.descricaoNcm), 1))
                  : (_openBlock(), _createElementBlock("span", _hoisted_28, _toDisplayString(text), 1))
          ]),
          emptyText: _withCtx(() => [
            _createVNode(_component_mensagem_sem_dados, {
              resultadoPesquisa: !_ctx.telaBase.carregando,
              mensagem: _ctx.telaBase.carregando ? 'Buscando informações, por favor aguarde...' : 'Nenhuma exceção definida'
            }, null, 8, ["resultadoPesquisa", "mensagem"])
          ]),
          _: 1
        }, 8, ["columns", "data-source", "loading", "scroll"]),
        _createElementVNode("div", _hoisted_29, [
          _createElementVNode("div", _hoisted_30, [
            _createElementVNode("button", {
              class: "ant-btn ant-btn-secondary",
              onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.incluirDefinicao(_ctx.ETipoImposto.Pis))),
              style: {"margin-top":"5px"}
            }, "Adicionar")
          ])
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_card, { titulo: "COFINS" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_31, [
          _createElementVNode("div", _hoisted_32, [
            _createVNode(_component_a_form_item, {
              label: "Busca Rápida",
              class: "ss-margin-campos"
            }, {
              default: _withCtx(() => [
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  class: "ant-input",
                  "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.state.buscaRapidaCofins) = $event)),
                  onKeyup: _cache[10] || (_cache[10] = ($event: any) => (_ctx.filtrarExcecoes(_ctx.ETipoImposto.Cofins)))
                }, null, 544), [
                  [_vModelText, _ctx.state.buscaRapidaCofins]
                ])
              ]),
              _: 1
            })
          ])
        ]),
        _createVNode(_component_a_table, {
          columns: _ctx.state.colunasCofins.filter(item => item.visible),
          "data-source": _ctx.computedExcecoesCofins.filter(item => item.visivel),
          rowKey: "codigo",
          pagination: false,
          loading: _ctx.telaBase.carregando,
          scroll: { y: _ctx.defineAlturaScroll(38) },
          size: "small"
        }, {
          bodyCell: _withCtx(({ column, record, text }) => [
            (column.key === 'Acoes')
              ? (_openBlock(), _createElementBlock("div", _hoisted_33, [
                  _createElementVNode("a", {
                    class: "visualizar",
                    onClick: ($event: any) => (_ctx.editarDefinicao(record))
                  }, [
                    _createVNode(_component_icone, { nome: "editar" })
                  ], 8, _hoisted_34),
                  _createElementVNode("a", {
                    onClick: ($event: any) => (_ctx.excluirDefinicao(record)),
                    class: "excluir"
                  }, [
                    _createVNode(_component_icone, { nome: "excluir" })
                  ], 8, _hoisted_35)
                ]))
              : (column.key === 'CodigoExcecao')
                ? (_openBlock(), _createElementBlock("span", _hoisted_36, _toDisplayString(record.tipoItem === 1 ? record.codigoInternoProduto : record.ncm), 1))
                : (column.key === 'DescricaoExcecao')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_37, _toDisplayString(record.tipoItem === 1 ? record.descricaoProduto : record.descricaoNcm), 1))
                  : (_openBlock(), _createElementBlock("span", _hoisted_38, _toDisplayString(text), 1))
          ]),
          emptyText: _withCtx(() => [
            _createVNode(_component_mensagem_sem_dados, {
              resultadoPesquisa: !_ctx.telaBase.carregando,
              mensagem: _ctx.telaBase.carregando ? 'Buscando informações, por favor aguarde...' : 'Nenhuma exceção definida'
            }, null, 8, ["resultadoPesquisa", "mensagem"])
          ]),
          _: 1
        }, 8, ["columns", "data-source", "loading", "scroll"]),
        _createElementVNode("div", _hoisted_39, [
          _createElementVNode("div", _hoisted_40, [
            _createElementVNode("button", {
              class: "ant-btn ant-btn-secondary",
              onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.incluirDefinicao(_ctx.ETipoImposto.Cofins))),
              style: {"margin-top":"5px"}
            }, "Adicionar")
          ])
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_cenario_fiscal_definicao_icms_modal, {
      empresa: _ctx.props.empresa,
      visivel: _ctx.state.exibirDefinicaoIcms,
      "onUpdate:visivel": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.state.exibirDefinicaoIcms) = $event)),
      definicao: _ctx.state.definicaoSelecionada,
      "onUpdate:definicao": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.state.definicaoSelecionada) = $event)),
      onConfirmar: _ctx.confirmarNovaDefinicao
    }, null, 8, ["empresa", "visivel", "definicao", "onConfirmar"]),
    _createVNode(_component_cenario_fiscal_definicao_ipi_modal, {
      empresa: _ctx.props.empresa,
      visivel: _ctx.state.exibirDefinicaoIpi,
      "onUpdate:visivel": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.state.exibirDefinicaoIpi) = $event)),
      definicao: _ctx.state.definicaoSelecionada,
      "onUpdate:definicao": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.state.definicaoSelecionada) = $event)),
      onConfirmar: _ctx.confirmarNovaDefinicao
    }, null, 8, ["empresa", "visivel", "definicao", "onConfirmar"]),
    _createVNode(_component_cenario_fiscal_definicao_pis_modal, {
      empresa: _ctx.props.empresa,
      visivel: _ctx.state.exibirDefinicaoPis,
      "onUpdate:visivel": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.state.exibirDefinicaoPis) = $event)),
      definicao: _ctx.state.definicaoSelecionada,
      "onUpdate:definicao": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.state.definicaoSelecionada) = $event)),
      onConfirmar: _ctx.confirmarNovaDefinicao
    }, null, 8, ["empresa", "visivel", "definicao", "onConfirmar"]),
    _createVNode(_component_cenario_fiscal_definicao_cofins_modal, {
      empresa: _ctx.props.empresa,
      visivel: _ctx.state.exibirDefinicaoCofins,
      "onUpdate:visivel": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.state.exibirDefinicaoCofins) = $event)),
      definicao: _ctx.state.definicaoSelecionada,
      "onUpdate:definicao": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.state.definicaoSelecionada) = $event)),
      onConfirmar: _ctx.confirmarNovaDefinicao
    }, null, 8, ["empresa", "visivel", "definicao", "onConfirmar"])
  ], 64))
}