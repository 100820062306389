
import { defineComponent, computed } from 'vue';
import Icone from '@/core/components/Icone.vue';
import { IGradeBase } from '@/core/composables/GradeBase';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';

export default defineComponent({
  name: 'Paginacao',
  props: {
    gradeBase: {
      type: Object as () => IGradeBase,
      required: true,
    },
    labelRegistros: {
      type: String,
      default: 'registros',
    },
    mensagemLimiteTotalRegistros: {
      type: String,
      default: 'Limite total de registros. Lembre-se: Quanto menor a quantidade de registros mais rápido o seu resultado será apresentado.',
    },
    placeHolderTotalRegistros: {
      type: String,
      default: 'Total registros',
    },
  },
  components: {
    Icone,
  },
  emits: ['update:gradeBase', 'buscarDados'],
  setup(props, { emit }) {
    const computedGradeBase = computed({
      get: () => props.gradeBase,
      set: (objeto: IGradeBase) => {
        emit('update:gradeBase', objeto);
      },
    });

    function mudarPagina(current: number, pageSize: number) {
      computedGradeBase.value.paginacao.current = current;
      computedGradeBase.value.paginacao.pageSize = pageSize;
      computedGradeBase.value.buscarDados += 1;
      emit('buscarDados');
    }

    function mudarQuantidadeRegistrosPorPagina(current: number, pageSize: number) {
      computedGradeBase.value.paginacao.current = current === 1 ? current : 1;
      computedGradeBase.value.paginacao.pageSize = pageSize;
      computedGradeBase.value.buscarDados += 1;
      emit('buscarDados');
    }

    function alterarQuantidadeRegistroTotal(valor: any) {
      clearTimeout(computedGradeBase.value.timerRegistros);
      let totalRegistros = 100;
      computedGradeBase.value.timerRegistros = setTimeout(async () => {
        if (UtilitarioGeral.valorValido(valor)) {
          const limiteRegistros = UtilitarioMascara.removerMascaraNumero(valor);
          if (limiteRegistros > 0) {
            totalRegistros = limiteRegistros;
          }
        }
        computedGradeBase.value.paginacao.total = totalRegistros;
        computedGradeBase.value.totalRegistrosComMascara = UtilitarioMascara.mascararNumeroInteiro(totalRegistros);
        computedGradeBase.value.buscarDados += 1;
        emit('buscarDados');
      }, 600);
    }

    return {
      props,
      computedGradeBase,
      UtilitarioMascara,
      mudarQuantidadeRegistrosPorPagina,
      mudarPagina,
      alterarQuantidadeRegistroTotal,
    };
  },
});
