
import { computed, defineComponent, reactive } from 'vue';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import SelecionarCfop from '../../Cfops/SelecionarCfop.vue';
import SelecionarOrigemMercadoria from '../SelecionarOrigemMercadoria.vue';
import SelecionarCstIcms from '../SelecionarCstIcms.vue';
import SelecionarCsosn from '../SelecionarCsosn.vue';
import SelecionarModalidadeBaseCalculoIcms from '../SelecionarModalidadeBaseCalculoIcms.vue';
import { ICenarioFiscalDefinicao } from '@/models/Entidades/Cadastros/Tributacoes/CenariosFiscais/ICenarioFiscal';
import CenarioFiscalInformacaoComplementar from './CenarioFiscalInformacaoComplementar.vue';
import SelecionarTipoAtividade from '@/components/Compartilhados/SelecionarTipoAtividade.vue';

export default defineComponent({
  name: 'CenarioFiscalDefinicaoIcms',
  props: {
    definicao: {
      type: Object as () => ICenarioFiscalDefinicao,
      required: true,
    },
    tiposAtividade: {
      type: Array as () => number[],
      required: true,
    },
  },
  components: {
    CampoNumerico,
    SelecionarCfop,
    SelecionarOrigemMercadoria,
    SelecionarCstIcms,
    SelecionarCsosn,
    SelecionarModalidadeBaseCalculoIcms,
    SelecionarTipoAtividade,
    CenarioFiscalInformacaoComplementar,
  },
  emits: ['update:definicao', 'update:tiposAtividade'],
  setup(props, { emit }) {
    const state = reactive({});

    const computedDefinicao = computed({
      get: () => props.definicao,
      set: (definicao: ICenarioFiscalDefinicao) => {
        emit('update:definicao', definicao);
      },
    });

    const computedTiposAtividade = computed({
      get: () => props.tiposAtividade,
      set: (tipos: number[]) => {
        emit('update:tiposAtividade', tipos);
      },
    });

    return {
      props,
      state,
      computedDefinicao,
      computedTiposAtividade,
    };
  },
});
