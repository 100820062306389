import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "ant-table-column-title" }
const _hoisted_2 = { class: "ant-table-column-sorter ant-table-column-sorter-full" }
const _hoisted_3 = { class: "ant-table-column-sorter-inner ant-table-column-sorter-inner-full" }
const _hoisted_4 = {
  role: "img",
  "aria-label": "Ordenar crescente",
  class: "anticon anticon-caret-up ant-table-column-sorter-up"
}
const _hoisted_5 = {
  role: "img",
  "aria-label": "caret-down",
  class: "anticon anticon-caret-down ant-table-column-sorter-down"
}
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "ant-table-column-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icone = _resolveComponent("icone")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.props.column.ordering)
      ? (_openBlock(), _createElementBlock("div", {
          key: _ctx.props.column.key,
          class: "ss-coluna-ordenada"
        }, [
          _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.props.column.title), 1),
          _createElementVNode("span", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              (_openBlock(), _createElementBlock("a", {
                title: "Ordenação crescente",
                key: 'ord-asc' + _ctx.props.column.key,
                class: _normalizeClass([_ctx.ordenacaoAtiva(_ctx.props.column.key, 'ASC') ? 'ss-ordenacao-coluna-ativa' : 'ss-ordenacao-coluna']),
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.ordenarDados(_ctx.props.column.key, 'ASC')))
              }, [
                _createElementVNode("span", _hoisted_4, [
                  _createVNode(_component_icone, { nome: "ordem-crescente" })
                ])
              ], 2)),
              (_openBlock(), _createElementBlock("a", {
                title: "Ordenação decrescente",
                key: 'ord-desc' + _ctx.props.column.key,
                class: _normalizeClass([_ctx.ordenacaoAtiva(_ctx.props.column.key, 'DESC') ? 'ss-ordenacao-coluna-ativa' : 'ss-ordenacao-coluna']),
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.ordenarDados(_ctx.props.column.key, 'DESC')))
              }, [
                _createElementVNode("span", _hoisted_5, [
                  _createVNode(_component_icone, { nome: "ordem-decrescente" })
                ])
              ], 2))
            ])
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.props.column.title), 1)
        ]))
  ]))
}