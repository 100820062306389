
import { computed, defineComponent, reactive } from 'vue';
import { ICenarioFiscalDefinicao } from '@/models/Entidades/Cadastros/Tributacoes/CenariosFiscais/ICenarioFiscal';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import SelecionarCstPis from '../SelecionarCstPis.vue';
import SelecionarTipoCalculoImposto from '../SelecionarTipoCalculoImposto.vue';
import CenarioFiscalInformacaoComplementar from './CenarioFiscalInformacaoComplementar.vue';

export default defineComponent({
  name: 'CenarioFiscalDefinicaoPis',
  props: {
    definicao: {
      type: Object as () => ICenarioFiscalDefinicao,
      required: true,
    },
  },
  components: {
    CampoNumerico,
    SelecionarCstPis,
    SelecionarTipoCalculoImposto,
    CenarioFiscalInformacaoComplementar,
  },
  emits: ['update:definicao', 'update:tiposAtividade'],
  setup(props, { emit }) {
    const state = reactive({
    });

    const computedDefinicao = computed({
      get: () => props.definicao,
      set: (definicao: ICenarioFiscalDefinicao) => {
        emit('update:definicao', definicao);
      },
    });

    return {
      props,
      state,
      computedDefinicao,
    };
  },
});
