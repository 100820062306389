
import { computed, defineComponent, reactive } from 'vue';
import { ICenarioFiscalDefinicao, ICenarioFiscalDefinicaoIcmsTipoAtividade } from '@/models/Entidades/Cadastros/Tributacoes/CenariosFiscais/ICenarioFiscal';
import CenarioFiscalDefinicaoIcms from './CenarioFiscalDefinicaoIcms.vue';
import CenarioFiscalDuplicarDefinicaoIcmsModal from './CenarioFiscalDuplicarDefinicaoIcmsModal.vue';
import { IDTOEstado } from '@/models/DTO/Cadastros/Localizacoes/IDTOEstado';

export default defineComponent({
  name: 'CenarioFiscalDefinicoesEstadosIcms',
  props: {
    definicoes: {
      type: Array as () => ICenarioFiscalDefinicao[],
      required: true,
    },
    tiposAtividades: {
      type: Array as () => Array<number>[],
      required: true,
    },
    estados: {
      type: Array as () => IDTOEstado[],
      required: true,
    },
  },
  components: {
    CenarioFiscalDefinicaoIcms,
    CenarioFiscalDuplicarDefinicaoIcmsModal,
  },
  emits: ['update:definicoes', 'update:tiposAtividades'],
  setup(props, { emit }) {
    const state = reactive({
      indexSelecionado: 0,
      estadoSelecionado: 0,
      exibirDuplicacao: false,
    });

    const computedDefinicoes = computed({
      get: () => props.definicoes,
      set: (definicoes: ICenarioFiscalDefinicao[]) => {
        emit('update:definicoes', definicoes);
      },
    });

    const computedTiposAtividade = computed({
      get: () => props.tiposAtividades,
      set: (tipos: Array<number>[]) => {
        emit('update:tiposAtividades', tipos);
      },
    });

    function abrirDuplicacao() {
      state.estadoSelecionado = props.definicoes[state.indexSelecionado].codigoEstado;
      state.exibirDuplicacao = true;
    }

    function duplicarEstados(estadosSelecionados: number[]) {
      const definicaoOriginal = props.definicoes.find((d) => d.codigoEstado === state.estadoSelecionado);
      const indexOriginal = props.definicoes.findIndex((d) => d.codigoEstado === state.estadoSelecionado);

      estadosSelecionados.forEach((e) => {
        const indexDefinicao = props.definicoes.findIndex((d) => d.codigoEstado === e);
        if (definicaoOriginal !== undefined && definicaoOriginal.siglaEstado !== computedDefinicoes.value[indexDefinicao].siglaEstado) {
          computedDefinicoes.value[indexDefinicao].definicaoIcms.codigoBeneficioFiscal = definicaoOriginal.definicaoIcms.codigoBeneficioFiscal;
          computedDefinicoes.value[indexDefinicao].definicaoIcms.codigoCfop = definicaoOriginal.definicaoIcms.codigoCfop;
          computedDefinicoes.value[indexDefinicao].definicaoIcms.aliquotaFcp = definicaoOriginal.definicaoIcms.aliquotaFcp;
          computedDefinicoes.value[indexDefinicao].definicaoIcms.aliquotaIcms = definicaoOriginal.definicaoIcms.aliquotaIcms;
          computedDefinicoes.value[indexDefinicao].definicaoIcms.calcularIcmsInterestadual = definicaoOriginal.definicaoIcms.calcularIcmsInterestadual;
          computedDefinicoes.value[indexDefinicao].definicaoIcms.cfop = definicaoOriginal.definicaoIcms.cfop;
          computedDefinicoes.value[indexDefinicao].definicaoIcms.cson = definicaoOriginal.definicaoIcms.cson;
          computedDefinicoes.value[indexDefinicao].definicaoIcms.cstIcms = definicaoOriginal.definicaoIcms.cstIcms;
          computedDefinicoes.value[indexDefinicao].definicaoIcms.naoContribuinteIcms = definicaoOriginal.definicaoIcms.naoContribuinteIcms;
          computedDefinicoes.value[indexDefinicao].definicaoIcms.contribuinteIcms = definicaoOriginal.definicaoIcms.contribuinteIcms;
          computedDefinicoes.value[indexDefinicao].definicaoIcms.pessoaSuperSimples = definicaoOriginal.definicaoIcms.pessoaSuperSimples;
          computedDefinicoes.value[indexDefinicao].definicaoIcms.informacaoComplementar = definicaoOriginal.definicaoIcms.informacaoComplementar;
          computedDefinicoes.value[indexDefinicao].definicaoIcms.modalidadeBaseCalculoIcms = definicaoOriginal.definicaoIcms.modalidadeBaseCalculoIcms;
          computedDefinicoes.value[indexDefinicao].definicaoIcms.motivoDesoneracaoIcms = definicaoOriginal.definicaoIcms.motivoDesoneracaoIcms;
          computedDefinicoes.value[indexDefinicao].definicaoIcms.origemMercadoria = definicaoOriginal.definicaoIcms.origemMercadoria;
          computedDefinicoes.value[indexDefinicao].definicaoIcms.percentualDiferimentoIcms = definicaoOriginal.definicaoIcms.percentualDiferimentoIcms;
          computedDefinicoes.value[indexDefinicao].definicaoIcms.reducaoBaseCalculoIcms = definicaoOriginal.definicaoIcms.reducaoBaseCalculoIcms;
          computedDefinicoes.value[indexDefinicao].definicaoIcms.definicaoCst = definicaoOriginal.definicaoIcms.definicaoCst;
          computedDefinicoes.value[indexDefinicao].definicaoIcms.substituicao.aliquotaIcmsInterno = definicaoOriginal.definicaoIcms.substituicao.aliquotaIcmsInterno;
          computedDefinicoes.value[indexDefinicao].definicaoIcms.substituicao.aliquotaIcmsSubstituicao = definicaoOriginal.definicaoIcms.substituicao.aliquotaIcmsSubstituicao;
          computedDefinicoes.value[indexDefinicao].definicaoIcms.substituicao.margemValorAdicionado = definicaoOriginal.definicaoIcms.substituicao.margemValorAdicionado;
          computedDefinicoes.value[indexDefinicao].definicaoIcms.substituicao.modalidadeBaseCalculoIcmsSubstituicao = definicaoOriginal.definicaoIcms.substituicao.modalidadeBaseCalculoIcmsSubstituicao;
          computedDefinicoes.value[indexDefinicao].definicaoIcms.substituicao.pautaIcmsSubstituicao = definicaoOriginal.definicaoIcms.substituicao.pautaIcmsSubstituicao;
          computedDefinicoes.value[indexDefinicao].definicaoIcms.substituicao.reducaoBaseCalculoIcmsSubstituicao = definicaoOriginal.definicaoIcms.substituicao.reducaoBaseCalculoIcmsSubstituicao;
          computedDefinicoes.value[indexDefinicao].definicaoIcms.tiposAtividade = [] as ICenarioFiscalDefinicaoIcmsTipoAtividade[];
          computedTiposAtividade.value[indexDefinicao] = [] as number[];
          computedTiposAtividade.value[indexOriginal].forEach((t) => {
            computedTiposAtividade.value[indexDefinicao].push(t);
          });
          computedDefinicoes.value[indexDefinicao].definicaoIcms.icmsInterestadual.aliquotaInterestadual = definicaoOriginal.definicaoIcms.icmsInterestadual.aliquotaInterestadual;
          computedDefinicoes.value[indexDefinicao].definicaoIcms.icmsInterestadual.aliquotaInternaIcmsUfDestino = definicaoOriginal.definicaoIcms.icmsInterestadual.aliquotaInternaIcmsUfDestino;
          computedDefinicoes.value[indexDefinicao].definicaoIcms.icmsInterestadual.reducaoBaseCalculoUfDestino = definicaoOriginal.definicaoIcms.icmsInterestadual.reducaoBaseCalculoUfDestino;
        }
      });
    }

    return {
      props,
      state,
      computedDefinicoes,
      computedTiposAtividade,
      abrirDuplicacao,
      duplicarEstados,
    };
  },
});
