import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ant-row" }
const _hoisted_2 = { class: "ant-col ant-col-xs-24 ant-col-xl-24" }
const _hoisted_3 = { class: "ant-row" }
const _hoisted_4 = { class: "ant-col ant-col-xs-24 ant-col-xl-24" }
const _hoisted_5 = { class: "ant-row" }
const _hoisted_6 = { class: "ant-col ant-col-xs-6 ant-col-xl-6" }
const _hoisted_7 = { class: "ant-col ant-col-xs-6 ant-col-xl-6" }
const _hoisted_8 = { class: "ant-col ant-col-xs-6 ant-col-xl-6" }
const _hoisted_9 = { class: "ant-col ant-col-xs-6 ant-col-xl-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_selecionar_cst_Cofins = _resolveComponent("selecionar-cst-Cofins")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_selecionar_tipo_calculo_imposto = _resolveComponent("selecionar-tipo-calculo-imposto")!
  const _component_campo_numerico = _resolveComponent("campo-numerico")!
  const _component_cenario_fiscal_informacao_complementar = _resolveComponent("cenario-fiscal-informacao-complementar")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_a_form_item, {
          label: "Situação Tributária do COFINS",
          class: "ss-margin-campos"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_selecionar_cst_Cofins, {
              codigoSelecionado: _ctx.computedDefinicao.definicaoCofins.cstCofins,
              "onUpdate:codigoSelecionado": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedDefinicao.definicaoCofins.cstCofins) = $event))
            }, null, 8, ["codigoSelecionado"])
          ]),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_a_form_item, {
          label: "Tipo de Cálculo",
          class: "ss-margin-campos"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_selecionar_tipo_calculo_imposto, {
              codigoSelecionado: _ctx.computedDefinicao.definicaoCofins.tipoCalculoCofins,
              "onUpdate:codigoSelecionado": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.computedDefinicao.definicaoCofins.tipoCalculoCofins) = $event))
            }, null, 8, ["codigoSelecionado"])
          ]),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_a_form_item, {
          label: "Alíquota do COFINS",
          class: "ss-margin-campos"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_campo_numerico, {
              percentual: true,
              valor: _ctx.computedDefinicao.definicaoCofins.aliquotaCofins,
              "onUpdate:valor": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.computedDefinicao.definicaoCofins.aliquotaCofins) = $event))
            }, null, 8, ["valor"])
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_a_form_item, {
          label: "Redução da B.C do COFINS",
          class: "ss-margin-campos"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_campo_numerico, {
              percentual: true,
              valor: _ctx.computedDefinicao.definicaoCofins.reducaoBaseCalculoCofins,
              "onUpdate:valor": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.computedDefinicao.definicaoCofins.reducaoBaseCalculoCofins) = $event))
            }, null, 8, ["valor"])
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_a_form_item, {
          label: "Alíquota COFINS ST",
          class: "ss-margin-campos"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_campo_numerico, {
              percentual: true,
              valor: _ctx.computedDefinicao.definicaoCofins.aliquotaCofinsSubstituicao,
              "onUpdate:valor": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.computedDefinicao.definicaoCofins.aliquotaCofinsSubstituicao) = $event))
            }, null, 8, ["valor"])
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_a_form_item, {
          label: "Vlr Mínimo de COFINS por Unidade",
          class: "ss-margin-campos"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_campo_numerico, {
              monetario: true,
              valor: _ctx.computedDefinicao.definicaoCofins.valorUnidadeTributavelCofins,
              "onUpdate:valor": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.computedDefinicao.definicaoCofins.valorUnidadeTributavelCofins) = $event))
            }, null, 8, ["valor"])
          ]),
          _: 1
        })
      ])
    ]),
    _createVNode(_component_cenario_fiscal_informacao_complementar, {
      "informacao-complementar": _ctx.computedDefinicao.definicaoCofins.informacaoComplementar,
      "onUpdate:informacaoComplementar": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.computedDefinicao.definicaoCofins.informacaoComplementar) = $event))
    }, null, 8, ["informacao-complementar"])
  ]))
}