
import { defineComponent, reactive, watch } from 'vue';
import Icone from '@/core/components/Icone.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import {
  ICenarioFiscalDefinicao, ICenarioFiscalDefinicaoIcms, ICenarioFiscalDefinicaoIcmsInterestadual, ICenarioFiscalDefinicaoIcmsSubstituicao,
  ICenarioFiscalDefinicaoIcmsTipoAtividade,
} from '@/models/Entidades/Cadastros/Tributacoes/CenariosFiscais/ICenarioFiscal';
import CenarioFiscalDefinicaoIcms from './CenarioFiscalDefinicaoIcms.vue';
import { ETipoImposto } from '@/models/Enumeradores/Cadastros/Tributacoes/CenariosFiscais/ETipoImposto';
import Card from '@/core/components/Tela/Card.vue';
import SelecionarTipoItemCenarioFiscal from '../SelecionarTipoItemCenarioFiscal.vue';
import SelecionarEstado from '@/components/Cadastros/Localizacoes/Estados/SelecionarEstado.vue';
import { ETipoItemCenarioFiscal } from '@/models/Enumeradores/Cadastros/Tributacoes/CenariosFiscais/ETipoItemCenarioFiscal';
import SelecionarProduto from '@/components/Cadastros/Produtos/SelecionarProduto.vue';
import SelecionarNcm from '@/components/Cadastros/Produtos/SelecionarNcm.vue';

export default defineComponent({
  name: 'CenarioFiscalDefinicaoIcmsModal',
  props: {
    visivel: {
      type: Boolean,
    },
    empresa: {
      type: Number,
      required: true,
    },
    definicao: {
      type: Object as () => ICenarioFiscalDefinicao,
      required: true,
    },
  },
  components: {
    Icone,
    Card,
    CenarioFiscalDefinicaoIcms,
    SelecionarTipoItemCenarioFiscal,
    SelecionarEstado,
    SelecionarProduto,
    SelecionarNcm,
  },
  emits: ['update:visivel', 'confirmar'],
  setup(props, { emit }) {
    const { telaBase, apresentarMensagemAlerta } = useTelaBase();
    const { modalBase } = useModalBase(props, emit);

    const state = reactive({
      definicao: {} as ICenarioFiscalDefinicao,
      tiposAtividade: [] as number[],
    });

    function limparTela() {
      const definicao = {} as ICenarioFiscalDefinicao;
      definicao.codigoCenarioFiscal = 0;
      definicao.codigoEstado = 0;
      definicao.codigoNcm = 0;
      definicao.codigoProdutoDefinicao = 0;
      definicao.tipoItem = ETipoItemCenarioFiscal.Produto;
      definicao.siglaEstado = '';
      definicao.tipoImposto = ETipoImposto.Icms;
      definicao.visivel = true;
      definicao.definicaoIcms = {} as ICenarioFiscalDefinicaoIcms;
      definicao.definicaoIcms.definicaoCst = true;
      definicao.definicaoIcms.codigoCfop = 0;
      definicao.definicaoIcms.tiposAtividade = [];
      definicao.definicaoIcms.calcularIcmsInterestadual = false;
      definicao.definicaoIcms.substituicao = {} as ICenarioFiscalDefinicaoIcmsSubstituicao;
      definicao.definicaoIcms.icmsInterestadual = {} as ICenarioFiscalDefinicaoIcmsInterestadual;
      state.definicao = definicao;
      state.tiposAtividade = [];
    }

    function salvar() {
      if (state.definicao.codigoEstado === 0) {
        apresentarMensagemAlerta('O estado deve ser informado!');
        return;
      }
      if (state.definicao.codigoNcm === 0 && state.definicao.tipoItem === ETipoItemCenarioFiscal.Ncm) {
        apresentarMensagemAlerta('O NCM deve ser informado!');
        return;
      }
      if (state.definicao.codigoProdutoDefinicao === 0 && state.definicao.tipoItem === ETipoItemCenarioFiscal.Produto) {
        apresentarMensagemAlerta('O Produto deve ser informado!');
        return;
      }
      state.definicao.definicaoIcms.tiposAtividade = [];
      state.tiposAtividade.forEach((t) => {
        const tipo = {} as ICenarioFiscalDefinicaoIcmsTipoAtividade;
        tipo.codigo = 0;
        tipo.codigoCenarioFiscalDefinicaoIcms = state.definicao.codigo;
        tipo.tipoAtividade = t;
        state.definicao.definicaoIcms.tiposAtividade.push(tipo);
      });
      if (state.definicao.tipoItem === ETipoItemCenarioFiscal.Produto) {
        state.definicao.descricaoTipoItem = 'Produto';
      } else {
        state.definicao.descricaoTipoItem = 'NCM';
      }
      emit('confirmar', state.definicao);
      modalBase.computedVisivel = false;
    }

    watch(async () => modalBase.computedVisivel, async () => {
      telaBase.carregando = true;
      limparTela();
      if (modalBase.computedVisivel) {
        if (props.definicao.codigo !== -1) {
          state.definicao = props.definicao;
          state.tiposAtividade = [] as number[];
          state.definicao.definicaoIcms.tiposAtividade.forEach((t) => {
            state.tiposAtividade.push(t.tipoAtividade);
          });
        }
      }
      telaBase.carregando = false;
    });

    return {
      props,
      state,
      telaBase,
      modalBase,
      ETipoItemCenarioFiscal,
      salvar,
    };
  },
});
