
import { defineComponent, reactive, watch } from 'vue';
import Icone from '@/core/components/Icone.vue';
import SelecionarEstado from '@/components/Cadastros/Localizacoes/Estados/SelecionarEstado.vue';
import { useModalBase } from '@/core/composables/ModalBase';
import { IDTOEstado } from '@/models/DTO/Cadastros/Localizacoes/IDTOEstado';

export default defineComponent({
  name: 'CenarioFiscalDuplicarDefinicaoIcmsModal',
  props: {
    visivel: {
      type: Boolean,
    },
    estadoOrigem: {
      type: Number,
      required: true,
    },
    estados: {
      type: Array as () => IDTOEstado[],
      required: true,
    },
  },
  components: {
    Icone,
    SelecionarEstado,
  },
  emits: ['update:visivel', 'confirmar'],
  setup(props, { emit }) {
    const { modalBase } = useModalBase(props, emit);

    const state = reactive({
      estadosSelecionados: [] as number[],
      marcarTodos: false,
    });

    function confirmar() {
      let selecionados = state.estadosSelecionados;
      if (state.marcarTodos) {
        selecionados = [];
        props.estados.forEach((e) => {
          selecionados.push(e.codigo);
        });
      }
      emit('confirmar', selecionados);
      modalBase.computedVisivel = false;
    }

    watch(async () => modalBase.computedVisivel, async () => {
      if (modalBase.computedVisivel) {
        state.estadosSelecionados = [] as number[];
        state.marcarTodos = false;
      }
    });

    return {
      props,
      state,
      modalBase,
      confirmar,
    };
  },
});
