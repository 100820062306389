
import { defineComponent, reactive, watch } from 'vue';
import Icone from '@/core/components/Icone.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import {
  ICenarioFiscalDefinicao,
  ICenarioFiscalDefinicaoIpi,
  ICenarioFiscalDefinicaoIpiTipoAtividade,
} from '@/models/Entidades/Cadastros/Tributacoes/CenariosFiscais/ICenarioFiscal';
import CenarioFiscalDefinicaoIpi from './CenarioFiscalDefinicaoIpi.vue';
import { ETipoImposto } from '@/models/Enumeradores/Cadastros/Tributacoes/CenariosFiscais/ETipoImposto';
import Card from '@/core/components/Tela/Card.vue';
import SelecionarTipoItemCenarioFiscal from '../SelecionarTipoItemCenarioFiscal.vue';
import { ETipoItemCenarioFiscal } from '@/models/Enumeradores/Cadastros/Tributacoes/CenariosFiscais/ETipoItemCenarioFiscal';
import SelecionarProduto from '@/components/Cadastros/Produtos/SelecionarProduto.vue';
import SelecionarNcm from '@/components/Cadastros/Produtos/SelecionarNcm.vue';

export default defineComponent({
  name: 'CenarioFiscalDefinicaoIpiModal',
  props: {
    visivel: {
      type: Boolean,
    },
    empresa: {
      type: Number,
      required: true,
    },
    definicao: {
      type: Object as () => ICenarioFiscalDefinicao,
      required: true,
    },
  },
  components: {
    Icone,
    Card,
    CenarioFiscalDefinicaoIpi,
    SelecionarTipoItemCenarioFiscal,
    SelecionarProduto,
    SelecionarNcm,
  },
  emits: ['update:visivel', 'confirmar'],
  setup(props, { emit }) {
    const { telaBase, apresentarMensagemAlerta } = useTelaBase();
    const { modalBase } = useModalBase(props, emit);

    const state = reactive({
      definicao: {} as ICenarioFiscalDefinicao,
    });

    function limparTela() {
      const definicao = {} as ICenarioFiscalDefinicao;
      definicao.codigo = 0;
      definicao.codigoCenarioFiscal = 0;
      definicao.codigoEstado = 0;
      definicao.codigoNcm = 0;
      definicao.codigoProdutoDefinicao = 0;
      definicao.tipoItem = ETipoItemCenarioFiscal.Produto;
      definicao.tipoImposto = ETipoImposto.Ipi;
      definicao.visivel = true;
      definicao.definicaoIpi = {} as ICenarioFiscalDefinicaoIpi;
      definicao.definicaoIpi.tiposAtividade = [];
      let i = 1;
      while (i <= 6) {
        const tipoAtividade = {} as ICenarioFiscalDefinicaoIpiTipoAtividade;
        tipoAtividade.tipoAtividade = i;
        definicao.definicaoIpi.tiposAtividade.push(tipoAtividade);
        i += 1;
      }
      definicao.definicaoIpi.especificarTipoAtividade = false;
      state.definicao = definicao;
    }

    function salvar() {
      if (state.definicao.codigoNcm === 0 && state.definicao.tipoItem === ETipoItemCenarioFiscal.Ncm) {
        apresentarMensagemAlerta('O NCM deve ser informado!');
        return;
      }
      if (state.definicao.codigoProdutoDefinicao === 0 && state.definicao.tipoItem === ETipoItemCenarioFiscal.Produto) {
        apresentarMensagemAlerta('O Produto deve ser informado!');
        return;
      }
      if (state.definicao.tipoItem === ETipoItemCenarioFiscal.Produto) {
        state.definicao.descricaoTipoItem = 'Produto';
      } else {
        state.definicao.descricaoTipoItem = 'NCM';
      }
      emit('confirmar', state.definicao);
      modalBase.computedVisivel = false;
    }

    watch(async () => modalBase.computedVisivel, async () => {
      telaBase.carregando = true;
      limparTela();
      if (modalBase.computedVisivel) {
        if (props.definicao.codigo !== -1) {
          state.definicao = props.definicao;
        }
      }
      telaBase.carregando = false;
    });

    return {
      props,
      state,
      telaBase,
      modalBase,
      ETipoItemCenarioFiscal,
      salvar,
    };
  },
});
