
import {
  computed, defineComponent, onBeforeMount, reactive,
} from 'vue';
import Card from '@/core/components/Tela/Card.vue';
import { ICenarioFiscalDefinicao } from '@/models/Entidades/Cadastros/Tributacoes/CenariosFiscais/ICenarioFiscal';
import { IDTOEstado } from '@/models/DTO/Cadastros/Localizacoes/IDTOEstado';
import { useTelaBase } from '@/core/composables/TelaBase';
import { IColumn } from '@/core/models/AntDesign/IColumn';
import { useGradeBase } from '@/core/composables/GradeBase';
import { ETipoImposto } from '@/models/Enumeradores/Cadastros/Tributacoes/CenariosFiscais/ETipoImposto';
import CenarioFiscalDefinicaoIcmsModal from './CenarioFiscalDefinicaoIcmsModal.vue';
import CenarioFiscalDefinicaoIpiModal from './CenarioFiscalDefinicaoIpiModal.vue';
import CenarioFiscalDefinicaoPisModal from './CenarioFiscalDefinicaoPisModal.vue';
import CenarioFiscalDefinicaoCofinsModal from './CenarioFiscalDefinicaoCofinsModal.vue';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import Icone from '@/core/components/Icone.vue';
import { ETipoItemCenarioFiscal } from '@/models/Enumeradores/Cadastros/Tributacoes/CenariosFiscais/ETipoItemCenarioFiscal';

export default defineComponent({
  name: 'CenarioFiscalExcecoes',
  props: {
    empresa: {
      type: Number,
      required: true,
    },
    excecoesIcms: {
      type: Array as () => ICenarioFiscalDefinicao[],
      required: true,
    },
    excecoesIpi: {
      type: Array as () => ICenarioFiscalDefinicao[],
      required: true,
    },
    excecoesPis: {
      type: Array as () => ICenarioFiscalDefinicao[],
      required: true,
    },
    excecoesCofins: {
      type: Array as () => ICenarioFiscalDefinicao[],
      required: true,
    },
    estados: {
      type: Array as () => IDTOEstado[],
      required: true,
    },
  },
  components: {
    Card,
    Icone,
    MensagemSemDados,
    CenarioFiscalDefinicaoIcmsModal,
    CenarioFiscalDefinicaoIpiModal,
    CenarioFiscalDefinicaoPisModal,
    CenarioFiscalDefinicaoCofinsModal,
  },
  emits: ['update:excecoesIcms', 'update:excecoesIpi', 'update:excecoesPis', 'update:excecoesCofins'],
  setup(props, { emit }) {
    const { telaBase } = useTelaBase();
    const { defineAlturaScroll } = useGradeBase();

    const state = reactive({
      colunasIcms: {} as IColumn[],
      colunasIpi: {} as IColumn[],
      colunasPis: {} as IColumn[],
      colunasCofins: {} as IColumn[],
      indexSelecionado: -1,
      definicaoSelecionada: {} as ICenarioFiscalDefinicao,
      buscaRapidaIcms: '',
      buscaRapidaIpi: '',
      buscaRapidaPis: '',
      buscaRapidaCofins: '',
      exibirDefinicaoIcms: false,
      exibirDefinicaoIpi: false,
      exibirDefinicaoPis: false,
      exibirDefinicaoCofins: false,
    });

    const computedExcecoesIcms = computed({
      get: () => props.excecoesIcms,
      set: (definicoes: ICenarioFiscalDefinicao[]) => {
        emit('update:excecoesIcms', definicoes);
      },
    });

    const computedExcecoesIpi = computed({
      get: () => props.excecoesIpi,
      set: (definicoes: ICenarioFiscalDefinicao[]) => {
        emit('update:excecoesIpi', definicoes);
      },
    });

    const computedExcecoesPis = computed({
      get: () => props.excecoesPis,
      set: (definicoes: ICenarioFiscalDefinicao[]) => {
        emit('update:excecoesPis', definicoes);
      },
    });

    const computedExcecoesCofins = computed({
      get: () => props.excecoesCofins,
      set: (definicoes: ICenarioFiscalDefinicao[]) => {
        emit('update:excecoesCofins', definicoes);
      },
    });

    function preencherColunas() {
      state.colunasIcms = [
        {
          title: 'Código', dataIndex: 'codigo', key: 'Codigo', position: 1, visible: false, ordering: false,
        },
        {
          title: 'Estado', dataIndex: 'siglaEstado', key: 'SiglaEstado', position: 2, visible: true, ordering: true, width: 70, align: 'center',
        },
        {
          title: 'Tipo', dataIndex: 'descricaoTipoItem', key: 'DescricaoTipoItem', position: 3, visible: true, ordering: true, width: 100, align: 'center',
        },
        {
          title: 'Código', dataIndex: 'codigoExcecao', key: 'CodigoExcecao', position: 4, visible: true, ordering: true, width: 120,
        },
        {
          title: 'Descrição', dataIndex: 'descricaoExcecao', key: 'DescricaoExcecao', position: 5, visible: true, ordering: true,
        },
        {
          title: 'Ações', key: 'Acoes', position: 6, visible: true, ordering: true, width: 70, align: 'center',
        },
      ];
      state.colunasIpi = [
        {
          title: 'Código', dataIndex: 'codigo', key: 'Codigo', position: 1, visible: false, ordering: false,
        },
        {
          title: 'Tipo', dataIndex: 'descricaoTipoItem', key: 'DescricaoTipoItem', position: 2, visible: true, ordering: true, width: 100, align: 'center',
        },
        {
          title: 'Código', dataIndex: 'codigoExcecao', key: 'CodigoExcecao', position: 3, visible: true, ordering: true, width: 120,
        },
        {
          title: 'Descrição', dataIndex: 'descricaoExcecao', key: 'DescricaoExcecao', position: 4, visible: true, ordering: true,
        },
        {
          title: 'Ações', key: 'Acoes', position: 5, visible: true, ordering: true, width: 70, align: 'center',
        },
      ];
      state.colunasPis = [
        {
          title: 'Código', dataIndex: 'codigo', key: 'Codigo', position: 1, visible: false, ordering: false,
        },
        {
          title: 'Tipo', dataIndex: 'descricaoTipoItem', key: 'DescricaoTipoItem', position: 2, visible: true, ordering: true, width: 100, align: 'center',
        },
        {
          title: 'Código', dataIndex: 'codigoExcecao', key: 'CodigoExcecao', position: 3, visible: true, ordering: true, width: 120,
        },
        {
          title: 'Descrição', dataIndex: 'descricaoExcecao', key: 'DescricaoExcecao', position: 4, visible: true, ordering: true,
        },
        {
          title: 'Ações', key: 'Acoes', position: 5, visible: true, ordering: true, width: 70, align: 'center',
        },
      ];
      state.colunasCofins = [
        {
          title: 'Código', dataIndex: 'codigo', key: 'Codigo', position: 1, visible: false, ordering: false,
        },
        {
          title: 'Tipo', dataIndex: 'descricaoTipoItem', key: 'DescricaoTipoItem', position: 2, visible: true, ordering: true, width: 100, align: 'center',
        },
        {
          title: 'Código', dataIndex: 'codigoExcecao', key: 'CodigoExcecao', position: 3, visible: true, ordering: true, width: 120,
        },
        {
          title: 'Descrição', dataIndex: 'descricaoExcecao', key: 'DescricaoExcecao', position: 4, visible: true, ordering: true,
        },
        {
          title: 'Ações', key: 'Acoes', position: 5, visible: true, ordering: true, width: 70, align: 'center',
        },
      ];
    }

    function filtrarExcecoes(tipoImposto: ETipoImposto) {
      switch (tipoImposto) {
        case ETipoImposto.Icms:
          computedExcecoesIcms.value.forEach((d, index) => {
            if (d.siglaEstado.toLowerCase().includes(state.buscaRapidaIcms.toLowerCase())
              || (d.tipoItem === ETipoItemCenarioFiscal.Produto && (d.descricaoProduto.toLowerCase().includes(state.buscaRapidaIcms.toLowerCase()) || d.codigoInternoProduto.toLowerCase().includes(state.buscaRapidaIcms.toLowerCase())))
              || (d.tipoItem === ETipoItemCenarioFiscal.Ncm && (d.descricaoNcm.toLowerCase().includes(state.buscaRapidaIcms.toLowerCase()) || d.ncm.toLowerCase().includes(state.buscaRapidaIcms.toLowerCase())))) {
              computedExcecoesIcms.value[index].visivel = true;
            } else {
              computedExcecoesIcms.value[index].visivel = false;
            }
          });
          break;
        case ETipoImposto.Ipi:
          computedExcecoesIpi.value.forEach((d, index) => {
            if ((d.tipoItem === ETipoItemCenarioFiscal.Produto && (d.descricaoProduto.toLowerCase().includes(state.buscaRapidaIpi.toLowerCase()) || d.codigoInternoProduto.toLowerCase().includes(state.buscaRapidaIpi.toLowerCase())))
                || (d.tipoItem === ETipoItemCenarioFiscal.Ncm && (d.descricaoNcm.toLowerCase().includes(state.buscaRapidaIpi.toLowerCase()) || d.ncm.toLowerCase().includes(state.buscaRapidaIpi.toLowerCase())))) {
              computedExcecoesIpi.value[index].visivel = true;
            } else {
              computedExcecoesIpi.value[index].visivel = false;
            }
          });
          break;
        case ETipoImposto.Pis:
          computedExcecoesPis.value.forEach((d, index) => {
            if ((d.tipoItem === ETipoItemCenarioFiscal.Produto && (d.descricaoProduto.toLowerCase().includes(state.buscaRapidaPis.toLowerCase()) || d.codigoInternoProduto.toLowerCase().includes(state.buscaRapidaPis.toLowerCase())))
                || (d.tipoItem === ETipoItemCenarioFiscal.Ncm && (d.descricaoNcm.toLowerCase().includes(state.buscaRapidaPis.toLowerCase()) || d.ncm.toLowerCase().includes(state.buscaRapidaPis.toLowerCase())))) {
              computedExcecoesPis.value[index].visivel = true;
            } else {
              computedExcecoesPis.value[index].visivel = false;
            }
          });
          break;
        case ETipoImposto.Cofins:
          computedExcecoesCofins.value.forEach((d, index) => {
            if ((d.tipoItem === ETipoItemCenarioFiscal.Produto && (d.descricaoProduto.toLowerCase().includes(state.buscaRapidaCofins.toLowerCase()) || d.codigoInternoProduto.toLowerCase().includes(state.buscaRapidaCofins.toLowerCase())))
              || (d.tipoItem === ETipoItemCenarioFiscal.Ncm && (d.descricaoNcm.toLowerCase().includes(state.buscaRapidaCofins.toLowerCase()) || d.ncm.toLowerCase().includes(state.buscaRapidaCofins.toLowerCase())))) {
              computedExcecoesCofins.value[index].visivel = true;
            } else {
              computedExcecoesCofins.value[index].visivel = false;
            }
          });
          break;
        default:
          break;
      }
    }

    function confirmarNovaDefinicao(definicao: ICenarioFiscalDefinicao) {
      let index = -1;
      switch (definicao.tipoImposto) {
        case ETipoImposto.Icms:
          index = computedExcecoesIcms.value.findIndex((d) => d.codigoEstado === definicao.codigoEstado && ((definicao.tipoItem === 1 && d.codigoProdutoDefinicao === definicao.codigoProdutoDefinicao) || (definicao.tipoItem === 2 && d.codigoNcm === definicao.codigoNcm)));
          if (index === -1) {
            computedExcecoesIcms.value.push(definicao);
          } else {
            computedExcecoesIcms.value[index] = definicao;
          }
          state.buscaRapidaIcms = '';
          break;
        case ETipoImposto.Ipi:
          index = computedExcecoesIpi.value.findIndex((d) => (definicao.tipoItem === 1 && d.codigoProdutoDefinicao === definicao.codigoProdutoDefinicao) || (definicao.tipoItem === 2 && d.codigoNcm === definicao.codigoNcm));
          if (index === -1) {
            computedExcecoesIpi.value.push(definicao);
          } else {
            computedExcecoesIpi.value[index] = definicao;
          }
          state.buscaRapidaIpi = '';
          break;
        case ETipoImposto.Pis:
          index = computedExcecoesPis.value.findIndex((d) => (definicao.tipoItem === 1 && d.codigoProdutoDefinicao === definicao.codigoProdutoDefinicao) || (definicao.tipoItem === 2 && d.codigoNcm === definicao.codigoNcm));
          if (index === -1) {
            computedExcecoesPis.value.push(definicao);
          } else {
            computedExcecoesPis.value[index] = definicao;
          }
          state.buscaRapidaPis = '';
          break;
        case ETipoImposto.Cofins:
          index = computedExcecoesCofins.value.findIndex((d) => (definicao.tipoItem === 1 && d.codigoProdutoDefinicao === definicao.codigoProdutoDefinicao) || (definicao.tipoItem === 2 && d.codigoNcm === definicao.codigoNcm));
          if (index === -1) {
            computedExcecoesCofins.value.push(definicao);
          } else {
            computedExcecoesCofins.value[index] = definicao;
          }
          state.buscaRapidaCofins = '';
          break;
        default:
          break;
      }
    }

    function incluirDefinicao(tipoImposto: ETipoImposto) {
      state.definicaoSelecionada = {} as ICenarioFiscalDefinicao;
      state.definicaoSelecionada.codigo = -1;
      switch (tipoImposto) {
        case ETipoImposto.Icms:
          state.exibirDefinicaoIcms = true;
          break;
        case ETipoImposto.Ipi:
          state.exibirDefinicaoIpi = true;
          break;
        case ETipoImposto.Pis:
          state.exibirDefinicaoPis = true;
          break;
        case ETipoImposto.Cofins:
          state.exibirDefinicaoCofins = true;
          break;
        default:
          state.exibirDefinicaoIcms = false;
          state.exibirDefinicaoIpi = false;
          state.exibirDefinicaoPis = false;
          state.exibirDefinicaoCofins = false;
          break;
      }
    }

    function editarDefinicao(definicao: ICenarioFiscalDefinicao) {
      state.definicaoSelecionada = definicao;
      switch (definicao.tipoImposto) {
        case ETipoImposto.Icms:
          state.indexSelecionado = props.excecoesIcms.findIndex((d) => d.codigo === definicao.codigo);
          state.exibirDefinicaoIcms = true;
          break;
        case ETipoImposto.Ipi:
          state.indexSelecionado = props.excecoesIpi.findIndex((d) => d.codigo === definicao.codigo);
          state.exibirDefinicaoIpi = true;
          break;
        case ETipoImposto.Pis:
          state.indexSelecionado = props.excecoesPis.findIndex((d) => d.codigo === definicao.codigo);
          state.exibirDefinicaoPis = true;
          break;
        case ETipoImposto.Cofins:
          state.indexSelecionado = props.excecoesCofins.findIndex((d) => d.codigo === definicao.codigo);
          state.exibirDefinicaoCofins = true;
          break;
        default:
          state.indexSelecionado = -1;
          state.exibirDefinicaoIcms = false;
          state.exibirDefinicaoIpi = false;
          state.exibirDefinicaoPis = false;
          state.exibirDefinicaoCofins = false;
          break;
      }
    }

    function excluirDefinicao(definicao: ICenarioFiscalDefinicao) {
      let index = -1;

      switch (definicao.tipoImposto) {
        case ETipoImposto.Icms:
          index = props.excecoesIcms.findIndex((d) => d.codigo === definicao.codigo);
          computedExcecoesIcms.value.splice(index, 1);
          break;
        case ETipoImposto.Ipi:
          index = props.excecoesIpi.findIndex((d) => d.codigo === definicao.codigo);
          computedExcecoesIpi.value.splice(index, 1);
          break;
        case ETipoImposto.Pis:
          index = props.excecoesPis.findIndex((d) => d.codigo === definicao.codigo);
          computedExcecoesPis.value.splice(index, 1);
          break;
        case ETipoImposto.Cofins:
          index = props.excecoesCofins.findIndex((d) => d.codigo === definicao.codigo);
          computedExcecoesCofins.value.splice(index, 1);
          break;
        default:
          break;
      }
    }

    onBeforeMount(async () => {
      preencherColunas();
    });

    return {
      props,
      state,
      telaBase,
      ETipoImposto,
      defineAlturaScroll,
      computedExcecoesIcms,
      computedExcecoesIpi,
      computedExcecoesPis,
      computedExcecoesCofins,
      filtrarExcecoes,
      confirmarNovaDefinicao,
      incluirDefinicao,
      editarDefinicao,
      excluirDefinicao,
    };
  },
});
