import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "ant-row",
  style: {"padding":"10px"}
}
const _hoisted_2 = { class: "ant-col ant-col-xs-24 ant-col-xl-21" }
const _hoisted_3 = { class: "ant-col ant-col-xs-24 ant-col-xl-3" }
const _hoisted_4 = { class: "ant-input-affix-wrapper ss-paginacao-qtde-registros" }
const _hoisted_5 = ["placeholder"]
const _hoisted_6 = { class: "ant-input-suffix" }
const _hoisted_7 = {
  tabindex: "-1",
  role: "img",
  "aria-label": "info-circle",
  class: "anticon anticon-info-circle",
  style: {"color":"rgba(0, 0, 0, 0.45)"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_pagination = _resolveComponent("a-pagination")!
  const _component_icone = _resolveComponent("icone")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _directive_maska = _resolveDirective("maska")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_a_pagination, {
          class: "ss-paginacao-completa",
          current: _ctx.computedGradeBase.paginacao.current,
          "onUpdate:current": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedGradeBase.paginacao.current) = $event)),
          size: "small",
          total: _ctx.gradeBase.paginacaoApi.totalRegistros,
          "show-total": (total, range) => `${_ctx.UtilitarioMascara.mascararNumeroInteiro(range[0])} - ${_ctx.UtilitarioMascara.mascararNumeroInteiro(range[1])} de ${_ctx.UtilitarioMascara.mascararNumeroInteiro(total)} ${_ctx.props.labelRegistros} `,
          "page-size": _ctx.gradeBase.paginacao.pageSize,
          pageSizeOptions: _ctx.gradeBase.paginacao.pageSizeOptions,
          onShowSizeChange: _ctx.mudarQuantidadeRegistrosPorPagina,
          onChange: _ctx.mudarPagina
        }, null, 8, ["current", "total", "show-total", "page-size", "pageSizeOptions", "onShowSizeChange", "onChange"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, [
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.computedGradeBase.totalRegistrosComMascara) = $event)),
            onKeyup: _cache[2] || (_cache[2] = ($event: any) => (_ctx.alterarQuantidadeRegistroTotal(($event.target as HTMLInputElement).value))),
            placeholder: _ctx.props.placeHolderTotalRegistros,
            type: "text",
            class: "ant-input hidden"
          }, null, 40, _hoisted_5), [
            [_vModelText, _ctx.computedGradeBase.totalRegistrosComMascara],
            [_directive_maska, _ctx.UtilitarioMascara.formatoMascaraNumeroInteiro]
          ]),
          _createElementVNode("span", _hoisted_6, [
            _createVNode(_component_a_tooltip, {
              placement: "top",
              title: _ctx.props.mensagemLimiteTotalRegistros
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_7, [
                  _createVNode(_component_icone, { nome: "informacoes" })
                ])
              ]),
              _: 1
            }, 8, ["title"])
          ])
        ])
      ])
    ])
  ]))
}