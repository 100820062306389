
import { defineComponent, computed } from 'vue';
import Icone from '@/core/components/Icone.vue';
import { IColumn } from '@/core/models/AntDesign/IColumn';
import { IOrdenacao } from '@/core/models/Consulta/IOrdenacao';

export default defineComponent({
  name: 'ColunaGrade',
  props: {
    column: {
      type: Object as () => IColumn,
      required: true,
    },
    ordenacaoSelecionada: {
      type: Array as () => IOrdenacao[],
      required: true,
    },
  },
  components: {
    Icone,
  },
  emits: ['update:ordenacaoSelecionada', 'buscarDados'],
  setup(props, { emit }) {
    const computedOrdenacaoSelecionada = computed({
      get: () => props.ordenacaoSelecionada,
      set: (objeto: IOrdenacao[]) => {
        emit('update:ordenacaoSelecionada', objeto);
      },
    });

    function ordenacaoAtiva(key: string, sort: string): boolean {
      if (computedOrdenacaoSelecionada.value.length > 0) {
        const itemOrdem = computedOrdenacaoSelecionada.value.find(((coluna) => coluna.identificador === key));
        if (itemOrdem === undefined) {
          return false;
        }
        if (itemOrdem.identificador === key && itemOrdem.ordem === sort) {
          return true;
        }
      }
      return false;
    }

    function preencheOrdenacaoSelecionada(key: string, sort: string) {
      if (computedOrdenacaoSelecionada.value.length > 0) {
        const itemOrdem = computedOrdenacaoSelecionada.value.find(((coluna) => coluna.identificador === key));
        if (itemOrdem === undefined) {
          computedOrdenacaoSelecionada.value.push({ identificador: key, ordem: sort } as IOrdenacao);
        } else if (itemOrdem.ordem === sort) {
          const indice = computedOrdenacaoSelecionada.value.indexOf(itemOrdem);
          computedOrdenacaoSelecionada.value.splice(indice, 1);
        } else {
          itemOrdem.ordem = sort;
        }
      } else {
        computedOrdenacaoSelecionada.value.push({ identificador: key, ordem: sort } as IOrdenacao);
      }
    }

    function ordenarDados(key: string, sort: string) {
      preencheOrdenacaoSelecionada(key, sort);
      emit('buscarDados');
    }

    return {
      props,
      ordenacaoAtiva,
      ordenarDados,
    };
  },
});
